<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div>
          <img
            v-lazy="require('../assets/imgs/shadow_spectrum/banner-750.webp')"
            alt=""
          />
        </div>
        <!-- 横幅内容区域 -->
        <h2 class="title">领先的智能影像技术企业</h2>
        <p class="introduce">助力产业数字化转型 提速数字中国</p>
      </div>
      <!-- 影谱图片区域 -->
      <div class="introduce_one">
        <div class="img_one">
          <img v-lazy="require('../assets/imgs/shadow_spectrum/2-logo.webp')" />
        </div>
        <p @click="getUrl()">点击登录</p>
        <span>
          影谱科技，国内领先的智能影像技术企业，以智能视觉产业化为主要目标，对视觉内容的生产效率与呈现交互方式进行变革性创新，面向媒体、文化、科教、新消费等多行业领域提供一站式智能解决方案。推动机器影像跨越至智能影像时代，技术与产品已经大规模应用于“数字经济”、“智慧媒体”、“媒体融合”、“新时代精品工程”、“国家现代化教育2035计划”
          等国家战略计划。
        </span>
        <div class="img_two">
          <img v-lazy="require('../assets/imgs/shadow_spectrum/2-image.webp')" />
        </div>
      </div>
      <!-- 自主研发AI三引擎 打造全景影像生态 -->
      <div class="my_study">
        <p>自主研发AI三引擎 打造全景影像生态</p>
        <div class="box">
          <div>
            <h4>影像商业化引擎 ACM</h4>
            <div class="img_all">
              <img
                v-lazy="require('../assets/imgs/shadow_spectrum/3-1.webp')"
                alt=""
              />
            </div>
            <p>
              作为影像内容自动化生产基础设施，ACM为影像产业链从产制源头至渠道各链条提供技术服务，用自动化、批量化、智能化操作，代替人工拍摄及制作，解决影像生产链中大规模内容生产的问题，极大地降低人力成本，万倍产能提升。
            </p>
            <span>Automatic Content Marketing</span>
          </div>

          <div>
            <h4>影像工业化引擎 AGC</h4>
            <div class="img_all">
              <img
                v-lazy="require('../assets/imgs/shadow_spectrum/3-2.webp')"
                alt=""
              />
            </div>
            <p>
              作为全栈的AI视觉技术栈集群，AGC带来全新的智能影像检索、生成、渲染及创建工具集、云服务，升级的数字人解决方案，视频鉴伪溯源平台，及高质量光照与物理模拟系统，以AI生成技术赋能传媒、文教、体育等多个产业，加速文化教育产业数字化进程。
            </p>
            <span>Automatic Generated Content</span>
          </div>

          <div>
            <h4>数字孪生引擎 ADT</h4>
            <div class="img_all">
              <img
                v-lazy="require('../assets/imgs/shadow_spectrum/3-3.webp')"
                alt=""
              />
            </div>
            <p>
              作为影谱数字商业的关键技术壁垒，ADT是数字经济的新型基础设施，可快速完成全品类商品的解构、建模、交互。依托ADT和数字孪生工厂，影谱综合运用物联网及XR前沿技术，快速实现数字商品的规模化生产，创造面向未来的新型生活方式。
            </p>
            <span>Automatic Digital Twin</span>
          </div>
        </div>
      </div>
      <!-- 数字商业,智慧文娱...... -->
      <div class="centent_box">
        <h5>数字商业</h5>
        <p>塑造商品数字化新业态 以规模化产能助力数字经济发展</p>
        <div class="box_1">
          <div>
            <img src="../assets/imgs/shadow_spectrum/4-1-1.png" alt="" />
            <p>实体商品数字化</p>
          </div>

          <div>
            <img src="../assets/imgs/shadow_spectrum/4-1-2.png" alt="" />
            <p>商品解析无纸化</p>
          </div>

          <div>
            <img src="../assets/imgs/shadow_spectrum/4-1-3.png" alt="" />
            <p>商品溯源区块链</p>
          </div>

          <div>
            <img src="../assets/imgs/shadow_spectrum/4-1-4.png" alt="" />
            <p>中国数字智造工厂</p>
          </div>
        </div>
        <h5>智慧文娱</h5>
        <p>重塑文娱产业链生产及成本结构，创造增量市场提升行业整体价值</p>
        <div class="box_2">
          <div class="box_spn">
            <div>
              <img src="../assets/imgs/shadow_spectrum/4-2-1.png" alt="" />
            </div>
            <span>影像内容智能生产</span>
          </div>

          <div class="box_spn">
            <div>
              <img src="../assets/imgs/shadow_spectrum/4-2-2.png" alt="" />
            </div>
            <span>影像场景智能重建</span>
          </div>

          <div class="box_spn">
            <div>
              <img src="../assets/imgs/shadow_spectrum/4-2-3.png" alt="" />
            </div>
            <span>影像元素实时替换</span>
          </div>
        </div>
        <h5>智慧媒体</h5>
        <p>驱动媒体内容智能化变革 颠覆传统媒体生产模式</p>
        <div class="box_3">
          <div class="box_3_spn">
            <div>
              <img src="../assets/imgs/shadow_spectrum/4-3-1.png" alt="" />
            </div>
            <p>自动化生产</p>
          </div>
          <div class="box_3_spn">
            <div>
              <img src="../assets/imgs/shadow_spectrum/4-3-2.png" alt="" />
            </div>
            <p>智能化识别</p>
          </div>
          <div class="box_3_spn">
            <div>
              <img src="../assets/imgs/shadow_spectrum/4-3-3.png" alt="" />
            </div>
            <p>数据化挖掘</p>
          </div>
          <div class="box_3_spn">
            <div>
              <img src="../assets/imgs/shadow_spectrum/4-3-4.png" alt="" />
            </div>
            <p>结构化服务</p>
          </div>
          <div class="box_3_spn">
            <div>
              <img src="../assets/imgs/shadow_spectrum/4-3-5.png" alt="" />
            </div>
            <p>个性化推送</p>
          </div>
        </div>
        <h5>智能科教</h5>
        <p>引领科教产业链智慧化升级 实现教育领域全面数字化</p>
        <div class="box_4">
          <div>
            <img src="../assets/imgs/shadow_spectrum/4-4-1.png" alt="" />
            <p>纸质教材数字化</p>
          </div>

          <div>
            <img src="../assets/imgs/shadow_spectrum/4-4-2.png" alt="" />
            <p>授课模式多元化</p>
          </div>

          <div>
            <img src="../assets/imgs/shadow_spectrum/4-4-3.png" alt="" />
            <p>教学体验场景化</p>
          </div>

          <div>
            <img src="../assets/imgs/shadow_spectrum/4-4-4.png" alt="" />
            <p>教学质量可量化</p>
          </div>
        </div>
      </div>
      <div class="foot_box">
        <p>产学研合作践行者</p>
        <span>首个国家级智能影像领域技术研究示范基地</span>
        <div class="img_foot">
          <img
            v-lazy="require('../assets/imgs/shadow_spectrum/foot.webp')"
            alt=""
          />
        </div>
        <div class="foot_bot">
          <ul class="ul_one">
            <li>• 国家级文化和科技融合示范基地</li>
            <li>• 与北京大学成立智能影像生产技术联合实验室</li>
            <li>• 与中科院自动化所成立智能媒体计算联合实验室</li>
          </ul>
          <div></div>
          <ul class="ul_two">
            <li>• 中国智能科学技术领域 "吴文俊人工智能科技进步奖"</li>
            <li>• 中国智能化领域 "中国自动化学会钱学森奖"</li>
            <li>• 全国技术市场领域 "金桥奖"</li>
          </ul>
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "optimization_algorithm",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 获取组件模块封装的url
    getUrl() {
      this.shadow_spectrum();
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  height: 600px;
  color: #fff;
  position: relative;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    height: 67px;
    font-size: 48px;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 3px;
    text-align: left;
    position: absolute;
    left: 361px;
    top: 230px;
  }
  .introduce {
    width: 565px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 361px;
    top: 321px;
  }
}
//设置头部文字动画效果
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
}
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

// 影谱样式
.introduce_one {
  .img_one {
    width: 1600px;
    height: 292px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 145px;
    margin-bottom: 79px;
    // loading大小
    img[lazy="loading"] {
      width: 150px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  p {
    display: block;
    width: 200px;
    height: 50px;
    line-height: 50px;
    background: #007aff;
    border-radius: 25px;
    border: 3px solid #fff;
    position: absolute;
    top: 1006px;
    left: 850px;
    color: #fff;
    font-size: 20px;
    letter-spacing: 5px;
    cursor: pointer;
  }
  span {
    display: inline-block;
    width: 1200px;
    margin-bottom: 91px;
    font-size: 24px;
    font-weight: 400;
    // color: #565656;
    text-align: left;
  }
  .img_two {
    width: 1200px;
    height: 300px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    // loading大小
    img[lazy="loading"] {
      width: 150px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
}
.my_study {
  width: 100%;
  height: 860px;
  background: #f6f8fb;
  padding-top: 80px;
  p {
    margin-bottom: 80px;
    height: 53px;
    font-size: 40px;
    font-weight: 400;
    color: #333333;
  }
  .box {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    div {
      width: 380px;
      height: 600px;
      background: #fff;
      border-radius: 10px;
      h4 {
        margin-top: 30px;
        margin-bottom: 30px;
        height: 29px;
        font-size: 23px;
        font-weight: 500;
        color: #333333;
      }
      .img_all {
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        // loading大小
        img[lazy="loading"] {
          width: 100px;
        }
        // 实际图片大小
        img[lazy="loaded"] {
          width: 100%;
          height: 100%;
        }
      }
      p {
        height: 200px;
        font-size: 15px;
        font-weight: 400;
        color: #7a7a7a;
        margin-bottom: -5px;
        text-align: left;
        padding: 0 30px;
        letter-spacing: 2px;
      }
      span {
        font-size: 18px;
        font-weight: 400;
        color: #99999f;
        opacity: 0.6;
      }
    }
  }
}
.centent_box {
  h5 {
    margin-top: 100px;
    margin-bottom: 60px;
    font-size: 40px;
    font-weight: 400;
    color: #333333;
  }
  p {
    margin-bottom: 60px;
    font-size: 26px;
    font-weight: 400;
    color: #333333;
  }
  .box_1,
  .box_2,
  .box_3,
  .box_4 {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .box_1,
  .box_4 {
    div {
      width: 285px;
      height: 300px;
      background: #fff;
      box-shadow: 3px 5px 20px 0px rgba(184, 184, 184, 0.4);
      border-radius: 4px;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 65px;
        height: 65px;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 0;
        margin-top: 60px;
        letter-spacing: 2px;
      }
    }
  }
  .box_2 {
    .box_spn {
      width: 386px;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 3px 5px 20px 0px rgba(184, 184, 184, 0.5);
      border-radius: 10px;
      div {
        width: 47px;
        height: 47px;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
          margin-right: 24px;
          vertical-align: middle;
        }
      }
      span {
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        letter-spacing: 2px;
      }
    }
  }
  .box_3 {
    .box_3_spn {
      width: 224px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      box-shadow: 3px 5px 20px 0px rgba(184, 184, 184, 0.5);
      div {
        margin-right: 15px;
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }
  }
}
.foot_box {
  margin-top: 100px;
  height: 750px;
  background: #f6f8fb;
  padding-top: 100px;
  p {
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: 400;
    color: #333333;
  }
  span {
    display: block;
    margin-bottom: 60px;
    font-size: 26px;
    font-weight: 400;
    color: #333333;
  }
  .img_foot {
    height: 175px;
    width: 781px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    // loading大小
    img[lazy="loading"] {
      width: 100px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .foot_bot {
    margin: auto;
    width: 1200px;
    height: 165px;
    margin-top: 66px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .ul_one,
    .ul_two {
      text-align: left;
      height: 165px;
      font-size: 24px;
      font-weight: 400;
      color: #565656;
      line-height: 60px;
    }
    div {
      width: 2px;
      height: 163px;
      background: #565656;
      opacity: 0.4;
    }
  }
}
</style>
